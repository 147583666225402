import CONFIG from "@/config";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import {
  KeycloakInstance,
  VueKeycloakInstance,
} from "@dsb-norge/vue-keycloak-js/dist/types";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import { KeycloakError } from "keycloak-js";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChartBar, faExpand } from "@fortawesome/free-solid-svg-icons";

library.add(faChartBar, faExpand);

createApp(App)
  .use(router)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(VueKeyCloak, {
    init: {
      onLoad: "login-required",
      checkLoginIframe: false,
      enableLogging: true,
      // redirectUri: "http://localhost:8080/",
    },
    config: {
      realm: CONFIG.KEYCLOAK_REALM,
      url: CONFIG.KEYCLOAK_URL,
      clientId: CONFIG.KEYCLOAK_CLIENT_ID,
    },
  })
  .mount("#app");

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $keycloak: VueKeycloakInstance;
  }
}
