
import api from "@/api";
import config from "@/config";
import { PowerBiReport } from "@/model/PowerBiReport";
import { defineComponent } from "vue";
import { useStore } from "vuex";

import { Modal } from "bootstrap";

export default defineComponent({
  name: "Home",
  components: {},
  data() {
    return {
      modalTitle: "",
      modalMessage: "",
      reports: [] as PowerBiReport[],
      colors: [
        {
          id: 1,
          name: "green",
        },
        {
          id: 2,
          name: "orange",
        },
        {
          id: 3,
          name: "blue",
        },
      ],
    };
  },
  methods: {
    getClassColor(index: number) {
      const i = index + 1;
      let idColor = (i % this.colors.length) + 1;
      return "graph-button-" + this.colors.find((e) => e.id === idColor)?.name;
    },
  },
  mounted() {
    const store = useStore();
    store.state.isLoading = true;
    const groupId = config.PBI_GROUP_ID;

    api
      .getReports(this.$keycloak.token as string, groupId)
      .then((res) => {
        this.reports = res;
      })
      .catch(() => {
        this.modalTitle = "Ocorreu um erro no acesso à lista de relatórios";
        this.modalMessage = "Sem acesso aos relatórios";
        const modal = new Modal(this.$refs.modal as HTMLElement);
        modal.show();
      })
      .finally(() => (store.state.isLoading = false));
  },
});
