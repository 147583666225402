const BASE_URL = process.env.BASE_URL;
const SERVER_ENDPOINT = process.env.VUE_APP_SERVER_ENDPOINT;
const KEYCLOAK_REALM = process.env.VUE_APP_KEYCLOAK_REALM;
const KEYCLOAK_URL = process.env.VUE_APP_KEYCLOAK_URL;
const KEYCLOAK_CLIENT_ID = process.env.VUE_APP_KEYCLOAK_CLIENT_ID;
const PBI_GROUP_ID = process.env.VUE_APP_PBI_GROUP_ID;
const APP_ENV = process.env.VUE_APP_ENV;

export default {
  APP_ENV,
  BASE_URL,
  SERVER_ENDPOINT,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
  KEYCLOAK_CLIENT_ID,
  PBI_GROUP_ID,
};
