
import api from "@/api";

import * as pbi from "powerbi-client";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { Modal } from "bootstrap";
import { useStore } from "vuex";

export default defineComponent({
  name: "Report",
  components: {},
  data() {
    return { modalTitle: "", modalMessage: "" };
  },
  methods: {
    fullscreen() {
      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      const embedContainer = this.$refs.container as HTMLElement;

      // Get a reference to the embedded report.
      const report = powerbi.get(embedContainer);

      // Displays the report in full screen mode.
      report.fullscreen();
    },
  },
  mounted() {
    const route = useRoute();
    const store = useStore();
    store.state.isLoading = true;
    const groupId = route.params.groupid as string;
    const reportId = route.params.reportid as string;
    const container = this.$refs.container as HTMLElement;

    api
      .getEmbedToken(this.$keycloak.token as string, groupId, reportId)
      .then((res) => {
        const permissions = pbi.models.Permissions.All;
        const config = {
          type: "report",
          tokenType: pbi.models.TokenType.Embed,
          accessToken: res.embedToken.token,
          embedUrl: res.reportsDetail.embedUrl,
          pageView: "fitToWidth",
          permissions: permissions,
        };

        const powerbi = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory
        );

        const dashboard = powerbi.embed(container, config);

        dashboard.off("loaded");
        dashboard.off("rendered");
        dashboard.on("error", () => {
          dashboard.off("error");
        });
      })
      .catch(() => {
        this.modalTitle = "Ocorreu um erro no acesso ao relatório";
        this.modalMessage = "Sem acesso aos relatório";
        const modal = new Modal(this.$refs.modal as HTMLElement);
        modal.show();
      })
      .finally(() => (store.state.isLoading = false));
  },
});
