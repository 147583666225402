
import { Modal } from "bootstrap";
import { defineComponent } from "vue";
import Loading from "./components/Loading.vue";
import config from "./config";

export default defineComponent({
  components: { Loading },
  name: "App",
  methods: {
    showModal() {
      const modal = new Modal(this.$refs.exampleModal as HTMLElement);
      modal.show();
    },
  },
  computed: {
    isProd(): boolean {
      return config.APP_ENV === "production";
    },
  },
});
