import CONFIG from "@/config";
import axios, { AxiosError } from "axios";
import { EmbedConfig } from "@/model/EmbedConfig";
import { PowerBiReport } from "@/model/PowerBiReport";

const BASE_ENDPOINT = CONFIG.SERVER_ENDPOINT;

function handleError(reason: AxiosError): void {
  if (reason.response?.status === 400) {
    throw Error("Parâmeterização inválida");
  } else if (reason.response?.status === 401) {
    window.location.reload();
    // throw Error(
    //   "Erro na autenticação, Se o erro persistir faça logout e entre novamente na aplicação."
    // );
  } else if (reason.response?.status === 403) {
    throw Error(
      reason.response?.data.message
        ? reason.response?.data.message
        : "Não tem permissão para executar esta ação"
    );
  } else if (reason.response?.status === 404) {
    throw Error(
      reason.response?.data.message
        ? reason.response?.data.message
        : "Página ou elemento não encontrado"
    );
  } else if (reason.response?.status === 500) {
    throw Error("Erro interno do Servidor");
  } else if (reason.response?.status === 503) {
    throw Error("Serviço Indisponível");
  }
  throw Error("ocorreu um erro a comunicar com o servidor: " + reason.message);
}

function getEmbedToken(
  accessToken: string,
  group: string,
  report: string
): Promise<EmbedConfig> {
  return axios
    .get(BASE_ENDPOINT + `/api/token/${group}/${report}`, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((reason: AxiosError) => handleError(reason));
}

function getReports(
  accessToken: string,
  group: string
): Promise<PowerBiReport[]> {
  return axios
    .get(BASE_ENDPOINT + `/api/groups/${group}/reports`, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((reason: AxiosError) => handleError(reason));
}

export default {
  handleError,
  getEmbedToken,
  getReports,
};
